import React from "react"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HTML from '../components/html';

import MoreLink from "../components/moreLink";

import groups from '../images/groups.png';

const ForGroupsPage = ({ intl }) => {
	return (
		<Layout pageName="prices" siteTitle="forGroups.page_title" intro="">
			<SEO
				lang={intl.locale}
				title={intl.formatMessage({ id: "title" })}
				description={intl.formatMessage({ id: "description" })}
				keywords={[`gatsby`, `application`, `react`]}
			/>
		
			<section className="wrapper container wrap narrow">
				<div className="solidBg overlap">
					<p><strong><FormattedMessage
						id="forGroups.p1"
						defaultMessage={intl.formatMessage({ id: "forGroups.p1" })}
						values={{b: chunks => <b>{chunks}</b>}}/></strong>
					</p>
					
					<p><FormattedMessage
						id="forGroups.p2"
						defaultMessage={intl.formatMessage({ id: "forGroups.p2" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<p><FormattedMessage
						id="forGroups.p3"
						defaultMessage={intl.formatMessage({ id: "forGroups.p3" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<p><FormattedMessage
						id="forGroups.p4"
						defaultMessage={intl.formatMessage({ id: "forGroups.p4" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<p><FormattedMessage
						id="forGroups.p5"
						defaultMessage={intl.formatMessage({ id: "forGroups.p5" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<img className="trip-map " src={groups} />
					
					{/* {intl.locale=="Polski"&&<>
					<h4>{intl.formatMessage({ id: "forGroups.h1" })}</h4>
					<b><p>{intl.formatMessage({ id: "forGroups.t1-h"})}</p></b>
					<p><FormattedMessage
						id="forGroups.t1-p1"
						defaultMessage={intl.formatMessage({ id: "forGroups.t1-p1" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<p><FormattedMessage
						id="forGroups.t1-p2"
						defaultMessage={intl.formatMessage({ id: "forGroups.t1-p2" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<p><FormattedMessage
						id="forGroups.t1-p3"
						defaultMessage={intl.formatMessage({ id: "forGroups.t1-p3" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<p><FormattedMessage
						id="forGroups.t1-p4"
						defaultMessage={intl.formatMessage({ id: "forGroups.t1-p4" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>

					<b><p>{intl.formatMessage({ id: "forGroups.t2-h"})}</p></b>
					<p><FormattedMessage
						id="forGroups.t2-p1"
						defaultMessage={intl.formatMessage({ id: "forGroups.t2-p1" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<p><FormattedMessage
						id="forGroups.t2-p2"
						defaultMessage={intl.formatMessage({ id: "forGroups.t2-p2" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<p><FormattedMessage
						id="forGroups.t2-p3"
						defaultMessage={intl.formatMessage({ id: "forGroups.t2-p3" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<p><FormattedMessage
						id="forGroups.t2-p4"
						defaultMessage={intl.formatMessage({ id: "forGroups.t2-p4" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<p><FormattedMessage
						id="forGroups.t2-p5"
						defaultMessage={intl.formatMessage({ id: "forGroups.t2-p5" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>

					<b><p>{intl.formatMessage({ id: "forGroups.t3-h"})}</p></b>
					<p><FormattedMessage
						id="forGroups.t3-p1"
						defaultMessage={intl.formatMessage({ id: "forGroups.t3-p1" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<p><FormattedMessage
						id="forGroups.t3-p2"
						defaultMessage={intl.formatMessage({ id: "forGroups.t3-p2" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<p><FormattedMessage
						id="forGroups.t3-p3"
						defaultMessage={intl.formatMessage({ id: "forGroups.t3-p3" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>

					<b><p>{intl.formatMessage({ id: "forGroups.t4-h"})}</p></b>
					<p><FormattedMessage
						id="forGroups.t4-p1"
						defaultMessage={intl.formatMessage({ id: "forGroups.t4-p1" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>

					<b><p>{intl.formatMessage({ id: "forGroups.t5-h"})}</p></b>
					<p><FormattedMessage
						id="forGroups.t5-p1"
						defaultMessage={intl.formatMessage({ id: "forGroups.t5-p1" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>

					<b><p>{intl.formatMessage({ id: "forGroups.t6-h"})}</p></b>
					<p><FormattedMessage
						id="forGroups.t6-p1"
						defaultMessage={intl.formatMessage({ id: "forGroups.t6-p1" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>

					<b><p>{intl.formatMessage({ id: "forGroups.t7-h"})}</p></b>
					<p><FormattedMessage
						id="forGroups.t7-p1"
						defaultMessage={intl.formatMessage({ id: "forGroups.t7-p1" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>

					<b><p>{intl.formatMessage({ id: "forGroups.t8-h"})}</p></b>
					<p><FormattedMessage
						id="forGroups.t8-p1"
						defaultMessage={intl.formatMessage({ id: "forGroups.t8-p1" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					</>} */}
				</div>
			</section>
		</Layout>
	)
}

export default injectIntl(ForGroupsPage)

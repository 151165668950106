import React from 'react';
import { injectIntl, Link, } from "gatsby-plugin-intl";

import arrowRight from '../images/arrow-right.svg';

const MoreLink = ({ intl, to, label, classList}) => {
	return (
		<Link to={to} className={`arrow fatLink ${classList}`}>
			<span>{label ? label : intl.formatMessage({ id: "learn_more" })}</span>
			<img src={arrowRight} />
		</Link>
	)
}

export default injectIntl(MoreLink);

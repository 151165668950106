import React from 'react'

const HTML = (props) => {
	return (
		<React.Fragment>
			{props.children}
		</React.Fragment>
	)
}

export default HTML